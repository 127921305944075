import React, { useEffect, useState } from "react";

import { Modal, Fade, Backdrop, makeStyles, CircularProgress } from "@material-ui/core";

import { format } from "date-fns";
import { normalizeCurrency } from "../utils/normalize";
import axios from 'axios';
import { toast } from 'react-toastify';

export default function ModalDetail({ open, setOpen, id }) {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "700px",
      height: "auto",
      color: "#666",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    async function loadDetail() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/payment-links/${id}`);
        setItem(data);
      }catch(err) {
        toast.error('Link não encontrado');
      }
      setLoading(false);
    }
    if(id) {
      loadDetail();
    }
  }, [id]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          {loading ? <CircularProgress /> : (
            <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Detalhamento</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            {Object.keys(item).length > 0 && (
              <div className="mt-4">
                <div className="d-flex justify-content-start align-items-center">
                  <i
                    className="fas fa-link mr-4"
                    style={{
                      background: "#e8f1eb",
                      padding: "15px",
                      borderRadius: "6px",
                      fontSize: "58px",
                    }}
                  ></i>
                  <div>
                    <p className="mb-2">
                      {`https://www.pele.tupi.io/link/${id}`}
                    </p>
                    <h4>{item.product}</h4>
                    <div className="d-flex">
                      <p className="mb-1">R$ {normalizeCurrency(item.value)}</p>
                      <p className="mb-1 ml-4">Quantidade: {item.amount}</p>
                      <p className="mb-1 ml-4">{item.installments}x</p>
                    </div>
                  </div>
                </div>
                <h3 className="text-center mt-3">Transações</h3>
                <div
                  className="mt-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridGap: "15px",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      padding: "20px",
                      background: "#e8f1eb",
                      border: "1px solid #cee8d7",
                      borderRadius: "6px"
                    }}
                  >
                    {item.orders.map(order => (
                      <div key={order.id}>
                        <p className="mb-1">ID: {order.reference}</p>
                        <p className="mb-1">
                          Status: <strong>{order.status}</strong>
                        </p>
                        <p className="mb-1">
                          NSU: <strong>{order.nsu}</strong>
                        </p>
                        <p className="mb-1">
                          Vendedor: <strong>{order.seller ? order?.seller?.name : '----'}</strong>
                        </p>
                        <p className="mb-1">
                          Data: {format(new Date(order.order_date), "dd/MM/yyyy - HH:mm")}
                        </p>
                        <p className="mb-1">
                          <i className="fas fa-address-card mr-1"></i> {order.cpf}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options ml-2"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Fechar
              </button>
            </div>
          </>
          )}
          </div>
      </Fade>
    </Modal>
  );
}
