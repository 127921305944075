import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { TextField, Button, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { normalizeCnpj, toCurrency } from '../utils/normalize';

import { toast } from "react-toastify";

function ProductAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    product: "",
    amount: "",
    value: "",
    installments: "",
    e_rede_credencial_id: "",
  });
  const [linkGenereated, setLinkGenerated] = useState('');
  const [credencialsOptions, setCredencialOptions] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('/me');
      setUser(data);
      setValues({
        ...values,
        e_rede_credencial_id: data.e_rede_credencial_id,
      })
    }
    loadUser();
  }, []) // eslint-disable-line

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get('/filials');
      setCredencialOptions(data);
    }
    loadData();
  }, [])

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post("/payment-links", {
        ...values, 
        installments: values.installments,
        amount: Number(values.amount.replace(/[^\d]+/g, "").replace(" ", "")),
        value: Number(values.value.replace(/[^\d]+/g, "").replace(" ", ""))
      });
      setValues({
        product: "",
        amount: "",
        value: "",
        installments: "",
        e_rede_credencial_id: "",
      });
      toast.info("Link de Pagamento cadastrado");
      try {
        const response = await axios.get(`/payment-links?page=${1}&perPage=${1}`);
        setLinkGenerated(`https://www.pele.tupi.io/link/${response.data.data[0].id}`)
      }catch(err) {
  
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        console.log(err)
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastrar Link de Pagamento</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/payment_links`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/payment_links/add`}
              >
                Cadastrar Link de Pagamento
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Novo Link de Pagamento</legend>
          {linkGenereated !== '' && (
            <p>
              <strong>Link gerado:</strong> {linkGenereated}
            </p>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Produto"
              variant="outlined"
              value={values.product}
              onChange={(e) => setValues({ ...values, product: e.target.value })}
            />
            <TextField
              label="Quantidade"
              variant="outlined"
              type="number"
              min="1"
              value={values.amount}
              onChange={(e) => setValues({ ...values, amount: e.target.value })}
            />
            <TextField
              label="Valor"
              multiline
              variant="outlined"
              value={values.value}
              onChange={(e) => setValues({ ...values, value: toCurrency(e.target.value) })}
            />
            <FormControl variant="outlined">
              <InputLabel id="installments_label">
                Parcelamento
              </InputLabel>
              <Select labelId="installments_label" value={values.installments} onChange={(e) => setValues({ ...values, installments: e.target.value })}>
                <MenuItem value={1}>1x</MenuItem>
                <MenuItem value={2}>2x</MenuItem>
                <MenuItem value={3}>3x</MenuItem>
                <MenuItem value={4}>4x</MenuItem>
                <MenuItem value={5}>5x</MenuItem>
                <MenuItem value={6}>6x</MenuItem>
                <MenuItem value={7}>7x</MenuItem>
                <MenuItem value={8}>8x</MenuItem>
                <MenuItem value={9}>9x</MenuItem>
                <MenuItem value={10}>10x</MenuItem>
                <MenuItem value={11}>11x</MenuItem>
                <MenuItem value={12}>12x</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="filial-label">
                Filial
              </InputLabel>
              <Select labelId="filial-label" value={values.e_rede_credencial_id} disabled={user.e_rede_credencial_id} onChange={(e) => setValues({ ...values, e_rede_credencial_id: e.target.value })}>
                {credencialsOptions.length > 0 && credencialsOptions.map(i => (
                  <MenuItem key={i.id} value={i.id}>{i.filial} | {normalizeCnpj(i.cnpj)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </fieldset>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={
            loading ||
            values.product === "" ||
            values.amount === "" ||
            values.value === "" ||
            values.installments === "" ||
            values.e_rede_credencial_id === "" || values.value === "R$ 0,00"
          }
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default ProductAdd;
