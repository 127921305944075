import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Paper, makeStyles, Button, CircularProgress } from "@material-ui/core";
import { Code, Edit, DeleteForever, Add, Check, Close, LiveHelp } from "@material-ui/icons";

import ModalConfirm from './components/ModalConfirm';

import { format } from "date-fns";

import { toast } from "react-toastify";

export default function ProductIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [add, setAdd] = useState({
    active: false,
    script: "",
  });
  const [remove, setRemove] = useState({
    active: false,
    id: null,
  });
  const [edit, setEdit] = useState({
    id: null,
    script: "",
  })

  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("/me");
      setUser(data);
    }
    loadUser();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
      minHeight: "350px",
    },
  }));
  const classes = useStyles();

  async function handleAdd() {
    setLoading(true);
    try {
      const { data } = await axios.post(`/company-scripts`, {
        script: add.script,
      });
      setAdd({ active: false, script: '' });
      setRows([...rows, data]);
    }catch(err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  async function handleEdit() {
    setLoading(true);
    try {
      const { data } = await axios.put(`/company-scripts/${edit.id}`, {
        script: add.script,
      });
      setEdit({ id: null, script: '' });
      setRows(rows.map(r => {
        if(r.id === data.id) {
          return data;
        } else {
          return r;
        }
      }));
    }catch(err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  async function handleRemove() {
    setLoading(true);
    try {
      await axios.delete(`/company-scripts/${remove.id}`);
      setRows(rows.filter(r => r.id !== remove.id));
      setRemove({ active: false, id: null });
    }catch(err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadScripts() {
      setLoading(true);
      try {
        const response = await axios.get(`/company-scripts`);
        setRows(response.data);
      } catch (err) {
        if (err.response && err.response.status === 403) {
          toast.error("Você não tem permissão para este recurso");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.msg);
        } else if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores, contate a equipe técnica"
          );
        } else {
          toast.error("Ocorreu um erro, tente novamente");
        }
      }
      setLoading(false);
    }
    loadScripts();
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Códigos de Incorporação</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm open={remove.active} setOpen={() => {
        setRemove({
          active: false,
          id: null,
        })
      }} onConfirm={handleRemove} loading={loading} />
      <Paper className={classes.paper}>
        <div className="row">
          <div className="col-md-8">
            <div className="d-flex align-items-start" style={{ padding: '30px', background: '#f9f9f9', borderRadius: '6px', border: '1px solid #eee' }}>
              <LiveHelp style={{ fontSize: '120px', color: '#666', marginRight: '25px' }}/>
              <div>
              {loading && (
                <CircularProgress />
              )}
              <h2>
                O que são os códigos de incorporação?
              </h2>
              <p style={{ fontSize: '16px' }}>
                Muito utilizados em Marketing Digital, é um código fornecido por um
                serviço para análise de movimentação de seu site/produto e também para
                métrica de acessos e receita de seus produtos em venda.
              </p>
              <p style={{ fontSize: '16px' }}>
                Atualmente nossa plataforma suporta o uso de qualquer integrador para
                métrica de acessos.
              </p>
              <p style={{ fontSize: '16px' }}>
                Em relação a receita de vendas, apenas são suportados as ferramentas do{' '}
                <strong>Google Tag Manager</strong> e <strong>Facebook Events</strong>.
              </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-start align-items-end flex-column">
              {!add.active && (
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={() =>
                    setAdd({
                      active: true,
                      script: "",
                    })
                  }
                  startIcon={<Add />}
                >
                  Adicionar Código
                </Button>
              )}
              {add.active && (
                <div
                  style={{
                    padding: "15px",
                    background: "#f0f0f0",
                    borderRadius: "6px",
                    width: "100%"
                  }}
                >
                  <textarea
                    rows="15"
                    style={{
                      resize: "none",
                      background: "#fff",
                      border: "1px solid #4242f3",
                      outline: "none",
                      padding: "10px",
                      width: "100%"
                    }}
                    disabled={loading}
                    placeholder="Copie o código aqui"
                    value={add.script}
                    onChange={(e) => {
                      setAdd({
                        ...add,
                        script: e.target.value,
                      });
                    }}
                  ></textarea>
                  <div className="d-flex justify-content-end">
                    {loading && (
                      <CircularProgress />
                    )}
                    <Button
                      type="button"
                      color="primary"
                      startIcon={<Check />}
                      disabled={loading || add.script === ''}
                      onClick={handleAdd}
                    >
                      Salvar
                    </Button>
                    <Button
                      style={{ color: 'red' }}
                      type="button"
                      color="secondary"
                      startIcon={<Close />}
                      disabled={loading}
                      onClick={() => {
                        setAdd({
                          active: false,
                          script: '',
                        })
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div id="list_scripts" className="mt-3" style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '10px', padding: '10px' }}>
              {rows &&
                rows.length > 0 &&
                rows.map((row) => {
                  const editActive = edit.id === row.id;
                  return (
                    <div className="d-flex align-items-start justify-content-start" style={{
                      padding: '15px',
                      background: '#f9f9f9',
                      borderRadius: '6px',
                      border: '1px solid #eee',
                    }} key={row.id}>
                      <div style={{ background: '#f0f0f0', padding: '15px', borderRadius: '6px' }} className="mr-4">
                        <Code style={{ fontSize: '45px' }}/>
                      </div>
                      {editActive ? (
                        <div
                        style={{
                          padding: "15px",
                          background: "#f0f0f0",
                          borderRadius: "6px",
                          width: "100%"
                        }}
                      >
                        <textarea
                          rows="15"
                          style={{
                            resize: "none",
                            background: "#fff",
                            border: "1px solid #4242f3",
                            outline: "none",
                            padding: "10px",
                            width: "100%"
                          }}
                          disabled={loading}
                          placeholder="Copie o código aqui"
                          value={edit.script}
                          onChange={(e) => {
                            setEdit({
                              ...edit,
                              script: e.target.value,
                            });
                          }}
                        ></textarea>
                        <div className="d-flex justify-content-end">
                          {loading && (
                            <CircularProgress />
                          )}
                          <Button
                            type="button"
                            color="primary"
                            startIcon={<Check />}
                            disabled={loading || edit.script === ''}
                            onClick={handleEdit}
                          >
                            Salvar
                          </Button>
                          <Button
                            style={{ color: 'red' }}
                            type="button"
                            color="secondary"
                            startIcon={<Close />}
                            disabled={loading}
                            onClick={() => {
                              setEdit({
                                id: null,
                                script: '',
                              })
                            }}
                          >
                            Cancelar
                          </Button>
                        </div>
                      </div>
                      ) : (
                        <div>
                        <h4>
                          {row.type
                            .split("-")
                            .join(" ")
                            .toUpperCase() || 'CÓDIGO DESCONHECIDO'}
                        </h4>
                        <p className="mb-1 mt-2">
                          Adicionado:{" "}
                          {format(
                            new Date(row.created_at),
                            "dd/MM/yyyy - HH:mm"
                          )}
                        </p>
                        {user.roles && user.roles.find((i) => i.slug === "owner") && (
                          <div>
                            <button
                              type="button"
                              style={{ background: "none", border: "none" }}
                              disabled={loading}
                              onClick={() => {
                                setEdit({
                                  id: row.id,
                                  script: row.script,
                                });
                              }}
                            >
                              <Edit />
                            </button>
                            <button
                              type="button"
                              style={{ background: "none", border: "none" }}
                              disabled={loading}
                              onClick={() => setRemove({
                                active: true,
                                id: row.id
                              })}
                            >
                              <DeleteForever />
                            </button>
                          </div>
                        )}
                      </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
