import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import ModalDetail from "./ModalDetail";

import { Pagination } from "react-bootstrap";
import { Visibility } from "@material-ui/icons";

import { normalizeCurrency } from "../utils/normalize";

import { format } from "date-fns";

import { toast } from "react-toastify";

export default function OrderIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [productFilter, setProductFilter] = useState(null);
  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("/me");
      setUser(data);
    }
    loadUser();
  }, []); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState({ active: false, item: {} });

  async function loadOrders() {
    setLoading(true);
    try {
      const response = await axios.get(
        `/orders?page=${page + 1}&perPage=${rowsPerPage}&e_rede_credencial_id=${
          user.e_rede_credencial_id
        }${productFilter !== null ? `&origin=${productFilter}` : ""}`
      );
      setRows(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadOrders();
  }, []); // eslint-disable-line

  useEffect(() => {
    loadOrders();
  }, [page, rowsPerPage, productFilter, user.e_rede_credencial_id]); // eslint-disable-line

  function handleNextPage() {
    const nextPage = page + 1;
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1;
    setPage(previousPage);
  }

  const headRows = [
    {
      id: "Produto",
      numeric: false,
      disablePadding: true,
      label: "Nº de Produtos",
    },
    { id: "client", numeric: false, disablePadding: true, label: "Cliente" },
    { id: "value", numeric: false, disablePadding: true, label: "Valor" },
    { id: "status", numeric: false, disablePadding: true, label: "Status" },
    { id: "seller", numeric: false, disablePadding: true, label: "Vendedor" },
    { id: "origin", numeric: false, disablePadding: true, label: "Origem" },
    { id: "date", numeric: false, disablePadding: true, label: "Data" },
    { id: "actions", numeric: false, disablePadding: true, label: "Ações" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Pedidos</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/orders`}>
                Lista
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <div
                className="d-flex"
                style={{
                  background: "#fff",
                  padding: "10px",
                  borderRadius: "6px",
                  minWidth: "250px",
                }}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel>Filtrar por Produto</InputLabel>
                  <Select
                    value={productFilter}
                    onChange={(e) => setProductFilter(e.target.value)}
                  >
                    <MenuItem value={"site"}>Site</MenuItem>
                    <MenuItem value={"checkout"}>Checkout</MenuItem>
                  </Select>
                </FormControl>
                {productFilter !== null && (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => setProductFilter(null)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDetail
        open={open.active}
        setOpen={() => setOpen({ active: false, item: {} })}
        item={open.item}
      />
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows &&
                rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="left">{row.products.length}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        R$ {normalizeCurrency(row.value)}
                      </TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.seller?.name}</TableCell>
                      <TableCell align="left">{row.origin}</TableCell>
                      <TableCell align="left">
                        {format(new Date(row.order_date), "dd/MM/yyyy HH:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn"
                          onClick={() =>
                            setOpen({
                              active: true,
                              item: row,
                            })
                          }
                        >
                          <Visibility />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && <CircularProgress size={15} className="mr-2" />}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First
                      onClick={() => setPage(0)}
                      disabled={page === 0 || loading}
                    />
                    <Pagination.Prev
                      onClick={() => handlePreviousPage()}
                      disabled={loading}
                    />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                {page < Math.ceil(total / rowsPerPage) - 1 && (
                  <>
                    <Pagination.Next
                      onClick={() => handleNextPage()}
                      disabled={loading}
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(total / rowsPerPage) - 1)
                      }
                      disabled={loading}
                    />
                  </>
                )}
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">
                Result. p/Página
              </InputLabel>
              <Select
                id="results_per_page"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
