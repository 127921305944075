import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Modal,
  Fade,
  Backdrop,
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { format } from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from 'react-toastify';

export default function PermissionIndex() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '500px'
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [deleteId, setDeleteId] = useState(null);
  const [total, setTotal] = useState(0);

  async function loadRoles() {
    setLoading(true);
    try {
      const response = await axios.get(`/roles`);
      setRows(response.data);
      setTotal(response.data.length);
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadRoles();
  }, []); // eslint-disable-line

  async function handleDelete() {
    await axios.delete(`/roles/${deleteId}`);
    setOpen(false);
    setDeleteId(null);
    await loadRoles();
  }

  function handleDeleteModal(id) {
    setDeleteId(id);
    setOpen(true);
  }


  const headRows = [
    { id: 'slug', numeric: false, disablePadding: true, label: 'Slug' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'description', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'updated-at', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'actions', numeric: false, disablePadding: true, label: 'Ações' },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Perfis
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/acl/roles`}>
                Lista
              </Link>
            </div>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <button
                ttype="button"
                onClick={() => history.push('/acl/roles/add')}
                className="btn btn-danger">
                Cadastrar Novo Perfil
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => { setOpen(!open); setDeleteId(null) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Confirmação</h2>
            <p id="transition-modal-description">Tem certeza que deseja excluir esta permissão?</p>
            <button type="button" className="btn btn-danger kt-subheader__btn-options" onClick={handleDelete}>Sim, excluir</button>
            <button type="button" className="btn btn-light kt-subheader__btn-options" onClick={() => { setOpen(false); setDeleteId(null) }}>Cancelar</button>
          </div>
        </Fade>
      </Modal>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
            {rows && rows.length > 0 && rows
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell align="left">{row.slug}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                       <TableCell align="left">
                         {format(new Date(row.updated_at), "dd/MM/yyyy")}
                       </TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => {
                            history.push(`/acl/roles/edit/${row.id}`)
                          }}>
                          <EditIcon />
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => handleDeleteModal(row.id)}>
                          <DeleteIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}